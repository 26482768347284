<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <el-form :inline='true' label-position='right' v-model='searchStatus'>
          <el-form-item>
            <el-input @clear='handleQuery' class='input-with-select' clearable
                      placeholder='请输入审批编号' v-model='searchStatus.code'></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model='optionsValue' placeholder='请选择' clearable @change='handlerQuery'>
              <el-option
                v-for='item in options'
                :key='item.value'
                :label='item.label'
                :value='item.value'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select filterable v-model='originatorValue' placeholder='请选择发起人' clearable
                       @change='handlerQuery'>
              <el-option
                v-for='item in originator'
                :key='item.originator_user_id'
                :label='item.nick'
                :value='item.originator_user_id'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select @change='handleQuery' filterable clearable placeholder='选择红人'
                       v-model='searchStatus.artist_id'>
              <el-option :key='index' :label='item.nickname' :value='item.artist_id'
                         v-for='(item,index) in kolList'>
                <span style='float: left'> {{ item.nickname }} </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model='statusValue' placeholder='状态' clearable @change='handlerQuery'>
              <el-option
                v-for='item in statusOptions'
                :key='item.value'
                :label='item.label'
                :value='item.value'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              @change='handlerQuery'
              v-model='pickerTime'
              type='month'
              value-format='yyyy-MM'
              placeholder='选择月'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              @change='handlerQuery'
              value-format='yyyy-MM-dd'
              v-model='finish_time'
              type='date'
              placeholder='审批时间'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click='handleQuery' type='primary'>查询</el-button>
            <div class='update download' v-if="userPermissions.indexOf('oa_manage_import') !== -1">
              <el-upload
                :before-upload='beforeUpload'
                :disabled='loadingUpload'
                :file-list='file'
                ref='upload'
                :auto-upload='false'
                :data='{http_id:httpId}'
                :headers='uploadHeaders'
                :on-change='handleChange'
                :on-success='importSuccess'
                :show-file-list='false'
                action='/admin_api/kol_approve/import'
                class='upload-demo'
                multiple
              >
                <el-button :icon="`${loadingUpload?'el-icon-loading':'el-icon-upload2'}`"
                           :loading='loadingUpload'
                           size='mini' type='primary'>批量导入
                </el-button>

              </el-upload>
              <el-button @click='uploadFile' type='primary'
                         style='margin-left: 10px' v-if="userPermissions.indexOf('oa_manage_import') !== -1">导入记录
              </el-button>
            </div>
            <export-btn :can-export="userPermissions.indexOf('oa_manage_import') !== -1"
                        :lintTitle="'下载模板'"
                        @export='downLoad'
                        class='update download'
                        ref='refExportLink' />
            <export-btn :can-export="userPermissions.indexOf('oa_manage_import') !== -1"
                        :lintTitle="'导出列表'"
                        @export='downLoadList'
                        class='update download'
                        ref='downLoadList' />
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-setting' @click='jumpToPage("oaSetting")'>设置</el-button>
          </el-form-item>
        </el-form>

        <div class='default-table'>
          <el-table
            :border='true'
            :data='tableData'
            :header-cell-style='headClass'
            ref='multipleTable'
            row-key='id'
            style='width: 100%'
            tooltip-effect='dark'
          >
            <!--                        <el-table-column-->
            <!--                                :reserve-selection="true"-->
            <!--                                key="selectable"-->
            <!--                                type="selection"-->
            <!--                                width="40">-->
            <!--                        </el-table-column>-->
            <el-table-column
              align='center'
              label='序号'
              width='80'
            >
              <template slot-scope='scope'>
                <span>{{ tablePagination.offset + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              label='审批编号'
              prop='code'
              width='150'
            >
            </el-table-column>
            <el-table-column
              align='center'
              label='审批名'
              min-width='150'
              prop='title'
            >
            </el-table-column>
            <el-table-column
              align='center'
              label='类型'
              width='120'
              prop='type_name'
            >
              <!--              <template slot-scope='{row}'>-->
              <!--                {{ statusData(row.type) }}-->
              <!--              </template>-->
            </el-table-column>
            <el-table-column
              align='center'
              label='发起人'
              prop='originator.nickname'
              show-overflow-tooltip
              width='80'
            >
              <template slot-scope='{row}'>
                {{ row?.originator?.nickname || '??' }}
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              label='归属红人'
              prop='nickname'
              width='120'
            >
              <!--              <template slot-scope='{row}'>-->
              <!--                <artist-nickname :info='{artist_id:row.artist_id,nickname:row.nickname}' />-->
              <!--              </template>-->
            </el-table-column>
            <el-table-column
              align='center'
              label='所属部门'
              min-width='150'
              prop='dpt_name'
            >
            </el-table-column>
            <el-table-column
              align='center'
              label='审批总金额'
              width='150'
              prop='total_amount'
            >
              <template slot-scope='{row}'>
                <span class='money'>{{ moneyFormat(row.total_amount||'')}}</span>
              </template>
            </el-table-column>
            <el-table-column
              align='left'
              header-align='center'
              label='用途'
              min-width='220'
              prop='purpose'
            >
            </el-table-column>
            <el-table-column
              align='center'
              label='审批时间'
              min-width='100'
              prop='finish_time'
            >
              <template slot-scope='{row}'>
                {{ $utils.parseTime(row?.finish_time || '', '{y}/{m}/{d}') }}
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              label='创建时间/修改时间'
              width='160'
              how-overflow-tooltip
            >
              <template slot-scope='{row}'>
                {{ row.created_at }} / {{ row.updated_at }}
              </template>
            </el-table-column>
            <el-table-column
              align='center'
              label='状态'
              prop='status'
              width='80'
            >
            </el-table-column>
            <el-table-column
              align='center'
              label='操作'
              width='100'>
              <template slot-scope='scope'>
                <el-popover
                  placement='right'
                  trigger='click'
                  width='400'>
                  <el-timeline :reverse='false'>
                    <el-timeline-item
                      :timestamp="scope.row.created_at||''"
                    >
                      <img :src='scope.row.originator?.avatar'
                           alt='' style='width: 30px;height: 30px;border-radius: 2px'>
                      {{ scope.row.originator?.nickname || '??' }} <a style='color: #ff3176'>创建了审批<a
                      v-if='scope.row.original_status==4'>（已撤销）</a>
                      <a
                        v-if='scope.row.original_status==5'>（已作废）</a></a>
                    </el-timeline-item>
                    <el-timeline-item
                      :key='index+activity?.nickname'
                      :timestamp="activity.finish_time||''"
                      v-for='(activity,index) in scope.row.tasks'>
                      <img :src='activity?.avatar'
                           alt='' style='width: 30px;height: 30px;border-radius: 2px'>

                      {{ activity?.nickname }} - <a
                      style='font-size: 20px'>
                      {{ activity.status }}</a>
                    </el-timeline-item>
                  </el-timeline>
                  <el-button size='small' slot='reference'
                             type='text'> 查看
                  </el-button>
                </el-popover>

                <el-button @click='handleClick(scope.row)'
                           key='artist_divide_item'
                           size='small'
                           style='margin-left: 6px' type='text'
                           v-if="userPermissions.indexOf('oa_manage_repeal') !== -1&&[2].indexOf(scope.row.original_status)>-1">
                  作废
                </el-button>

              </template>
            </el-table-column>
          </el-table>

          <el-row justify='right' type='flex'>
            <el-col :span='24'>
              <Pagination
                :limit.sync='tablePagination.page_size'
                :page.sync='tablePagination.current_page'
                :total='tablePagination.total'
                @pagination='getList' />
            </el-col>
          </el-row>

        </div>
      </div>

    </PageHeaderLayout>
    <!--    查看审批流程-->
    <el-dialog v-if='uploadFileShow' :before-close='handleClose' title='导入记录' :visible.sync='uploadFileShow'
               width='90%'>
      <upload-file-list :progressShow='progressShow' :percentage='percentage' :progressTextShow='progressTextShow'
                        @closeDialog='closeDialog' :fileName='fileName' :progressText='progressText'
                        :uploadType="'oa'"
                        :reloadData='reloadData' :uploadFileShow='uploadFileShow'></upload-file-list>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import ExportBtn from '@/components/export/ExportBtn'
import uploadFileList from '@/components/uploadIndex.vue'

export default {
  name: 'PublicationIndex',
  components: { PageHeaderLayout, ExportBtn, uploadFileList },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      file: [],
      //筛选data
      searchStatus: {
        code: '',
        artist_id: ''
      },
      // 数组data
      tableData: [],
      //数组页码
      tablePagination: {
        total: 0,
        page_size: 15,
        offset: 0,
        current_page: 1
      },
      loadingUpload: false,

      optionsValue: '',
      options: [
        { value: 1, label: '推广投放申请' },
        { value: 2, label: '红人费用申请' },
        { value: 3, label: '通用付款申请' },
        { value: 4, label: '通用报销申请' },
        { value: 5, label: '备用金申请' },
        { value: 6, label: '差旅报销申请' }
      ],
      statusValue: '',
      statusOptions: [
        { value: 1, label: '正在处理' },
        { value: 2, label: '审核通过' },
        { value: 3, label: '已拒绝' },
        { value: 4, label: '已撤销' },
        { value: 5, label: '已作废' }
      ],
      pickerTime: '',
      finish_time: '',
      httpId: '',
      progressShow: false,
      uploadFileShow: false,
      fileName: '',
      percentage: 0,
      progressText: '',
      progressTextShow: false,
      wsStop: false,
      reloadData: false,
      originator: [],
      originatorValue: '',
      kolList: []


    }
  },
  mounted() {
    this.httpId = Math.ceil(Math.random() * 999999999999)
  },
  methods: {
    init() {
      this.getOriginator()
      this.getKol()
      this.getList()
    },
    async getKol() {
      this.kolList = await this.$api.modelGetKolList()
    },
    closeDialog(val) {
      val ? this.uploadFileShow = false : ''
    },
    uploadFile() {
      this.uploadFileShow = true
      this.progressShow = false

    },
    async downLoad() {
      try {
        var isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          // let searchCondition = this.handleSearchCondition()
          let response = await this.$api.exportKolApprove()
          let name = `OA审批导入模板`
          this.$refs.refExportLink.exportSaveXlsx(response, name)
        }
      } catch (e) {
        this.$message.warning('浏览器不支持导出文件')
      }
    },
    async downLoadList() {
      try {
        let searchCondition = this.searchCondition()
        delete searchCondition.current_page
        delete searchCondition.page_size
        var isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          // let searchCondition = this.handleSearchCondition()
          let response = await this.$api.exportKolOAList({ is_export: true, ...searchCondition })
          let name = `OA审批列表`
          this.$refs.downLoadList.exportSaveXlsx(response, name)
        }
      } catch (e) {
        this.$message.warning('浏览器不支持导出文件')
      }
    },
    statusData(value) {
      let typeName = ''
      switch (value) {
        case 1:
          typeName = '推广投放申请'
          break
        case 2:
          typeName = '红人费用申请'
          break
        case 3:
          typeName = '通用付款申请'
          break
        case 4:
          typeName = '通用报销申请'
          break
        case 5:
          typeName = '备用金申请'
          break
        case 6:
          typeName = '差旅申请'
          break
      }
      return typeName
    },
    handleSelect() {

    },
    // moneyData(data) {
    //   let allMoney = ''
    //   if (data && data.length != 0) {
    //     data.forEach(({ name, value }) => {
    //       switch (name) {
    //         case '付款金额（元）':
    //           allMoney = value
    //           break
    //         case '总报销金额（元）':
    //           allMoney = value
    //           break
    //       }
    //     })
    //   }
    //
    //   return this.moneyFormat(allMoney) + '  元'
    // },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    async handleClick(row) {
      // /saveOaManage
      this.$confirm('是否确定作废该审批', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = {
          id: row.id
        }
        let id = await this.$api.repealOaManage(data)
        if (id) {
          this.$notify.success('成功')
          await this.getList()
        } else this.$notify.error('操作失败')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleClose() {
      this.wsStop = false
      this.uploadFileShow = false
      // this.uploadFileShow = true
      this.progressShow = false
      this.reloadData = false

    },
    ws() {
      if (!this.wsStop) {
        let url = ''
        if (process.env.NODE_ENV === 'development')
          url = `${process.env.VUE_APP_WS_URL}/oa/import?http_id=${this.httpId}`
        else {
          const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
          const hostname = window.location.hostname
          url = `${wsProtocol}//${hostname}${process.env.VUE_APP_WS_URL}/oa/import?http_id=${this.httpId}`
        }
        this.WebSocket = new WebSocket(url)
        if (url && url.length > 0) {

          this.WebSocket.onopen = () => {
            this.WebSocket.send('open')
            this.$refs.upload.submit()
          }
          this.WebSocket.onmessage = e => {
            this.wsOnMessage(e)
          }
          this.WebSocket.onerror = err => {
            console.log(err, 'err')
            // this.reconnect()
            // this.wsOnError(err)
          }
          this.WebSocket.onclose = res => {
            // alert(this.token)
            console.log('close', res)
          }
        } else this.$notify.warning('即时通讯地址为空')

      }
    },
    wsOnMessage(e) {
      // this.debugLogger('wsOnMessage:', e)
      const str = JSON.parse(e.data)
      if (!str) return
      if (str.type === 'upload' || str.type === 'parse') {
        this.progressTextShow = true
        this.progressText = str.msg
      }

      if (str.type === 'process') {
        this.percentage = str.process
        if (str.process == 100) {
          setTimeout(() => {
            this.progressTextShow = false
          }, 500)
          // this.progressShow = false
          this.wsClose()
        }
      }


    },
    handlerQuery() {
      this.tablePagination.current_page = 1
      this.getList()
    },
    searchCondition() {
      let searchCondition = {
        page_size: this.tablePagination.page_size,
        current_page: this.tablePagination.current_page
      }
      this.searchStatus.code !== '' ? searchCondition['code'] = this.searchStatus.code : ''
      this.searchStatus.artist_id !== '' ? searchCondition['artist_id'] = this.searchStatus.artist_id : ''
      this.optionsValue !== '' ? searchCondition['type'] = this.optionsValue : ''
      this.statusValue !== '' ? searchCondition['status'] = this.statusValue : ''
      this.originatorValue !== '' ? searchCondition['originator_user_id'] = this.originatorValue : ''
      this.finish_time !== '' ? searchCondition['finish_time'] = this.finish_time : ''
      if ((this.pickerTime || undefined) !== undefined) {
        searchCondition['year'] = Number(this.pickerTime.slice(0, 4))
        searchCondition['month'] = Number(this.pickerTime.slice(5, this.pickerTime.length))
      }
      // finish_time
      return searchCondition
    },
    async getList() {
      let searchCondition = this.searchCondition()
      let { list, page_info } = await this.$api.getOaManageList(searchCondition)
      this.$nextTick(() => {
        this.tableData = list
        this.tablePagination = page_info
        this.tablePagination.current_page = page_info.current_page || 1
        this.tablePagination.page_size = page_info.page_size || 1
        this.tablePagination.offset = page_info.offset || 0
        this.tablePagination.total = page_info.total
      })

    },
    async getOriginator() {
      let data = await this.$api.OaOriginator()
      this.originator = data.map(i => {
        return i.nick ? i : false
      }).filter(Boolean)
    },
    //批量样式居中
    headClass() {
      return 'text-align:center'
    },
    handleQuery() {
      this.tablePagination.current_page = 1
      this.getList()
    },
    //下载上传模块
    handleChange(file) {
      this.file = [file]
      this.fileName = this.file[0].name
      this.ws()
      this.wsStop = true
      this.progressShow = true

    },
    importSuccess(response) {
      this.loadingUpload = false
      this.loadingStatus = false
      if (response.code === 200) {
        this.$notify.success(response.data)
        this.submitted = []
        this.showProgress = false
        this.reloadData = true
        this.dataList = response.data.list || []
        this.getList()
      } else {
        this.$notify.warning(response.msg)
      }
    },
    beforeUpload() {
      this.httpId = Math.ceil(Math.random() * 999999999999)
      this.percentage = 0
      this.loadingUpload = true
      this.loadingStatus = true
      this.uploadFileShow = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    },
    wsClose() {
      if (this.WebSocket && this.WebSocket.readyState === this.WebSocket.OPEN) {
        this.WebSocket.close()
        this.WebSocket = null
      }
    },
    jumpToPage(pageName, query) {
      this.$router.push({
        name: pageName,
        query: query
      })
      console.log(pageName, query)
    }
  },
  watch: {
    '$route.matched': {
      immediate: true,
      handler(n) {
        if (n.length === 2) {
          this.init()
        }
      }
    }

  }
}
</script>
<style lang='scss'>
#foo > .el-checkbox__input.is-checked .el-checkbox__inner, .foo > * > .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border: 1px solid #409EFF;

}

#foo > * > .el-checkbox__inner:hover, .foo > * > * > .el-checkbox__inner:hover {
  border-color: #409EFF !important;
}

.foo > * > span:last-child {
  display: none;
}

.download {
  display: flex;
  float: right;
  margin-left: 10px;
}
</style>
